var Bowser = require('bowser'); // CommonJS
var etvpStyles = require('./etvp.css').toString();
var ETVPlayer = /** @class */ (function () {
    function ETVPlayer(elementId, options) {
        this.events = {
            stateChange: function () { return; },
            locationChange: function () { return; }
        };
        this.playerId = elementId;
        this.options = options || {};
        this.addCss();
        this.init();
    }
    ETVPlayer.prototype.onStateChange = function (cb) {
        this.events.stateChange = cb;
        // initial state
        if (!!this.state) {
            var stateChangeEvent = Object.assign(this.state || {}, { playerId: this.playerId, });
            cb(stateChangeEvent);
        }
    };
    ETVPlayer.prototype.onLocationChange = function (cb) {
        this.events.locationChange = cb;
        // initial location
        if (!!this.location) {
            var locationChangeEvent = Object.assign(this.location || {}, { playerId: this.playerId, });
            cb(locationChangeEvent);
        }
    };
    ETVPlayer.prototype.init = function () {
        console.log("Initialized new Player for id: " + this.playerId + ".");
        if (this.outdatedBrowser()) {
            this.createErrorScreen();
        }
        else {
            this.createIFrame();
        }
    };
    /**
     * https://caniuse.com/#feat=mediasource
     * https://caniuse.com/#feat=http-live-streaming
     */
    ETVPlayer.prototype.outdatedBrowser = function () {
        var browser = Bowser.getParser(window.navigator.userAgent);
        var isInvalidBrowser = browser.satisfies({
            // declare browsers per OS
            mobile: {
                Safari: '<3.2',
                Chrome: '<3',
            },
            desktop: {
                Safari: '<6',
            },
            'Internet Explorer': '<11',
            Firefox: '<42',
            Edge: '<12',
            Chrome: '<23',
        });
        return isInvalidBrowser;
    };
    ETVPlayer.prototype.createErrorScreen = function () {
        var errorDiv = document.createElement('div');
        errorDiv.classList.add('etv-outdatedbrowser');
        errorDiv.style.width = '100%';
        errorDiv.style.height = '100%';
        errorDiv.style.backgroundColor = '#2585C7';
        errorDiv.style.fontFamily = 'Helvetica,Arial';
        var textDiv = document.createElement('div');
        textDiv.innerHTML = 'OUTDATED BROWSER';
        textDiv.style.position = 'absolute';
        textDiv.style.color = 'white';
        textDiv.style.textAlign = 'center';
        textDiv.style.top = '47%';
        textDiv.style.width = '100%';
        errorDiv.appendChild(textDiv);
        this.addToDom(errorDiv);
    };
    ETVPlayer.prototype.createIFrame = function () {
        var playerUri = this.getPlayerUri();
        var wrapper = document.createElement('div');
        var iframe = document.createElement('iframe');
        iframe.setAttribute('src', "//" + playerUri + "?" + this.parseOptions());
        iframe.setAttribute('allowFullScreen', '');
        iframe.setAttribute('id', this.playerId + 'frame');
        iframe.frameBorder = '0';
        wrapper.classList.add('etvp-wrapper');
        wrapper.appendChild(iframe);
        this.addToDom(wrapper);
    };
    ETVPlayer.prototype.handshake = function () {
        var _this = this;
        var element = document.getElementById(this.playerId + 'frame');
        var onload = function () {
            var m = {
                parentOrigin: window.location.origin,
                parentHost: location.host,
                parentPathName: location.pathname,
                parentElementId: _this.playerId,
                type: 'handshake',
            };
            element.contentWindow.postMessage(m, '*');
            element.onload = null; // clear the handler, to run this code only once
        };
        // iFrame ready event
        if (window.addEventListener) {
            element.addEventListener('load', onload, false);
        }
        else if (window.attachEvent) {
            element.attachEvent('onload', onload);
        }
        else if (window.onLoad) {
            element.onload = onload;
        }
        // listen to player events
        var playerEvents = function (event) {
            if (event.data.type === 'setData') {
                var alreadyExistent = document.getElementById("pw_left_" + _this.playerId);
                if (event.data.powerlink && !alreadyExistent) {
                    var container = document.getElementById(_this.playerId);
                    // create powerlink div
                    var powerlinkWrapper = document.createElement('div');
                    var powerlinkHtml = _this.getPowerlink('earthTV.com', 'https://www.earthtv.com?utm_source=player&utm_medium=pl-right&utm_campaign=player-pl', _this.playerId);
                    powerlinkWrapper.classList.add('powerlink');
                    powerlinkWrapper.innerHTML = powerlinkHtml;
                    container.appendChild(powerlinkWrapper);
                    // create dfp
                    // add dfp script to container
                    var script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.text = _this.initDfp('live', event.data.accountId, event.data.sourceId, event.data.adTargetTag, _this.playerId);
                    container.appendChild(script);
                }
            }
            //  on state change
            if (event.data.type === 'etvp_onStateChange' && event.data.metadata.playerId === _this.playerId) {
                var change = event.data.metadata;
                if (!_this.state || (_this.state.isPaused !== change.isPaused || _this.state.isLive !== change.isLive)) {
                    _this.state = { isLive: change.isLive, isPaused: change.isPaused };
                    var stateChangeEvent = Object.assign(_this.state, { playerId: _this.playerId, });
                    _this.events.stateChange(stateChangeEvent);
                }
                if (!_this.location || (_this.location.city !== change.city || _this.location.country !== change.country)) {
                    _this.location = { city: change.city, country: change.country };
                    var locationChangeEvent = Object.assign(_this.location, { playerId: _this.playerId, });
                    _this.events.locationChange(locationChangeEvent);
                }
            }
        };
        window.addEventListener('message', playerEvents);
    };
    ETVPlayer.prototype.getPlayerUri = function () {
        var scripts = document.getElementsByTagName('script');
        var len = scripts.length;
        var re = /etvp\.js$/;
        var src;
        var etvPlayerSrc;
        while (len--) {
            src = scripts[len].src;
            if (src && src.match(re)) {
                etvPlayerSrc = src;
                break;
            }
        }
        // convert to hostname
        var tmp = document.createElement('a');
        tmp.href = etvPlayerSrc;
        return tmp.host;
    };
    ETVPlayer.prototype.addToDom = function (el) {
        var domEl = document.getElementById(this.playerId);
        if (domEl) {
            domEl.classList.add('etvplayer');
            domEl.appendChild(el);
            this.handshake();
        }
        else {
            throw new Error("No DOM element found with id " + this.playerId + ". Add '<div id=\"" + this.playerId + "\"></div>' to your html file.");
        }
    };
    ETVPlayer.prototype.parseOptions = function () {
        var _this = this;
        return Object.keys(this.options)
            .map(function (key) { return key + '=' + _this.options[key]; })
            .join('&');
    };
    ETVPlayer.prototype.addCss = function () {
        if (window.etvready) {
            return;
        }
        var head = document.head || document.getElementsByTagName('head')[0];
        var style = document.createElement('style');
        style.type = 'text/css';
        if (style.styleSheet) {
            style.styleSheet.cssText = etvpStyles;
        }
        else {
            style.appendChild(document.createTextNode(etvpStyles));
        }
        head.appendChild(style);
        window.etvready = true;
    };
    ETVPlayer.prototype.getPowerlink = function (powerlink_text, link, id) {
        return "<div class=\"powerlink_left\" id=\"pw_left_" + id + "\"></div>\n      <a id=\"pw_right_" + id + "\" class=\"powerlink_right\" data-id=\"" + id + "\" href=\"" + link + "\" target=\"_blank\">" + powerlink_text + "</a>";
    };
    /**
     * build responsive powerlinkg
     * https://support.google.com/dfp_sb/answer/3423562?visit_id=1-636265417505177230-508223590&rd=1
     */
    ETVPlayer.prototype.initDfp = function (type, accountId, sourceId, adTargetTag, id) {
        return ("var googletag = googletag || {};googletag.cmd = googletag.cmd || [];(function() {var gads = document.createElement('script');gads.async = true;gads.type = 'text/javascript';var useSSL = 'https:' == document.location.protocol;gads.src = (useSSL ? 'https:' : 'http:') +'//www.googletagservices.com/tag/js/gpt.js';var node = document.getElementsByTagName('script')[0];node.parentNode.insertBefore(gads, node);})();" +
            'googletag.cmd.push(function() {' +
            'var mapping = googletag.sizeMapping().addSize([0, 0], [320, 50]).addSize([459, 0],[468, 60]).build();' + // build responsive mapping
            'window.etvp_powerlink = googletag.defineSlot("/1052246/powerlink_default", [468, 60], "pw_left_' +
            id +
            '").defineSizeMapping(mapping).setCollapseEmptyDiv(false).addService(googletag.pubads());' +
            'googletag.pubads().enableSingleRequest();' +
            'googletag.pubads().enableAsyncRendering();' +
            'googletag.pubads().setTargeting("type", "' +
            type +
            '");' +
            'googletag.pubads().setTargeting("accountId", "' +
            accountId +
            '");' +
            'googletag.pubads().setTargeting("sourceId", "' +
            sourceId +
            '");' +
            'googletag.pubads().setTargeting("targettag", "' +
            adTargetTag +
            '");' +
            'googletag.enableServices();' +
            '});' +
            'googletag.cmd.push(function() {' +
            "googletag.display('pw_left_" +
            id +
            "');" +
            '});');
    };
    return ETVPlayer;
}());
export { ETVPlayer };
window.onload = function () {
    if (typeof onEtvApiReady === 'function') {
        onEtvApiReady(ETVPlayer);
    }
};
